import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchSubscriptionTiers } from '../services/fetchSubscriptionTiers';
import './Subscriptions.css';

function Subscriptions() {
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    async function getPlans() {
      const tiers = await fetchSubscriptionTiers();
      setPlans(tiers);
    }
    getPlans();
  }, []);

  const handleSelectPlan = (plan) => {
    navigate('/checkout', { state: { price: plan.price, bytes: plan.bytes, plan_id: plan.plan_id } });
  };

  return (
    <div className="subscriptions-container">
      <h1 className="subscriptions-title">Choose Your Plan</h1>
      <p className="subscriptions-description">
        Select the plan that best fits your needs. All prices are per month.
      </p>
      <div className="plans-list">
        {plans.map((plan) => (
          <div key={plan.plan_id} className="plan-card">
            <h2 className="plan-bytes">{plan.bytes} Byte{plan.bytes > 1 ? 's' : ''}</h2>
            <p className="plan-description">{plan.description}</p>
            <p className="plan-price">${plan.price.toFixed(2)} / month</p>
            <button
              className="plan-button"
              onClick={() => handleSelectPlan(plan)}
            >
              Select Plan
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Subscriptions;