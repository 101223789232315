// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/Loading.css */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it overlays other elements */
}

.loading-content {
  text-align: center;
  color: #ffffff;
}

.loading-cog-static {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  animation: spin 2s linear infinite; /* Add spinning animation */
}

.loading-text {
  font-size: 1.5rem;
  color: #ffffff;
}

/* Spinning keyframe animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAAA,2BAA2B;;AAE3B;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,6BAA6B;EACnE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,sCAAsC;AACvD;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kCAAkC,EAAE,2BAA2B;AACjE;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA,gCAAgC;AAChC;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["/* src/styles/Loading.css */\r\n\r\n.loading-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 1000; /* Ensure it overlays other elements */\r\n}\r\n\r\n.loading-content {\r\n  text-align: center;\r\n  color: #ffffff;\r\n}\r\n\r\n.loading-cog-static {\r\n  width: 100px;\r\n  height: 100px;\r\n  margin-bottom: 20px;\r\n  animation: spin 2s linear infinite; /* Add spinning animation */\r\n}\r\n\r\n.loading-text {\r\n  font-size: 1.5rem;\r\n  color: #ffffff;\r\n}\r\n\r\n/* Spinning keyframe animation */\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
