import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import './Home.css';
import image1 from '../assets/Connected.png';
import image2 from '../assets/Raid.png';
import image3 from '../assets/Afk.png';

function Home() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/subscriptions');
  };

  const imagesData = [
    { img: image1, text: 'Enjoy customizable settings for each Byte including AutoFeed, AutoIce, AutoCraft, AutoRecycle, and more!', reverse: false },
    { img: image2, text: "Enjoy peace of mind with our advanced raid detection. We support team notifications so you can all stay in the loop while using the ByteHold service.", reverse: true },
    { img: image3, text: 'Enjoy the AutoMove module to ensure you can safely take a break from ZMA without being killed in the idling zone.', reverse: false },
  ];

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <h1 className="hero-title">Welcome to ByteHold</h1>
        <p className="hero-subtitle">The Ultimate AFK Solution for Starve.io</p>
        <button className="cta-button" onClick={handleGetStarted}>
          Get Started
        </button>
      </section>

      {/* Features Section */}
      <section className="features">
        <h2 className="section-title">Why Choose ByteHold?</h2>
        <div className="features-list">
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            Reliable AFK Service
          </div>
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            Secure Token Management
          </div>
          <div className="feature-item">
            <FaCheck className="feature-icon" />
            24/7 Uptime
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2 className="section-title">What Our Users Say</h2>
        <div className="testimonial-list">
          <div className="testimonial">
            <p>"ByteHold is so good I just wanna smack the taste outta yo mouth!"</p>
            <div className="stars">★★★★★</div>
            <p>- SauceGodJay</p>
          </div>
          <div className="testimonial">
            <p>"I unfortunately don't have a life, so I've enjoyed using ByteHold to fill servers with 100 alts for easy world records. Thanks, ByteHold!"</p>
            <div className="stars">★★★★★</div>
            <p>- R3birth</p>
          </div>
          <div className="testimonial">
            <p>"It's nice to see such innovation in starve.io, especially when I never update the game!"</p>
            <div className="stars">★★★★★</div>
            <p>- LapaMauve</p>
          </div>
        </div>
      </section>

      {/* Images Section */}
      <section className="images-section">
        {imagesData.map((data, index) => (
          <div
            key={index}
            className={`image-text-row ${data.reverse ? 'reverse' : ''}`}
          >
            <img src={data.img} alt={`Feature ${index + 1}`} className="image" />
            <p className="image-text">{data.text}</p>
          </div>
        ))}
      </section>
    </div>
  );
}

export default Home;