export function fetchSubscriptionTiers() {
    return new Promise((resolve) => {
      const tiers = [
        { plan_id: 'tier-1', bytes: 1, price: 4.99, description: "1 concurrent session" },
        { plan_id: 'tier-3', bytes: 3, price: 12.99, description: "3 concurrent sessions" },
        { plan_id: 'tier-5', bytes: 5, price: 21.99, description: "5 concurrent sessions" },
        { plan_id: 'tier-10', bytes: 10, price: 39.99, description: "10 concurrent sessions" },
        { plan_id: 'tier-25', bytes: 25, price: 69.99, description: "25 concurrent sessions" },
        { plan_id: 'tier-50', bytes: 50, price: 119.99, description: "50 concurrent sessions" },
      ];
      setTimeout(() => resolve(tiers), 1000);
    });
  }