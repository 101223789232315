import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Success.css';
import { FaCheckCircle } from 'react-icons/fa'; // Success icon

function Success() {
  const location = useLocation();
  const { activationKey } = location.state || {};

  console.log('Activation Key:', activationKey);

  if (!activationKey) {
    return (
      <div className="error-container">
        <h1>Access Denied</h1>
        <p>It seems you tried to access this page without completing a purchase.</p>
        <p>Please complete a purchase to view your subscription details.</p>
        <Link to="/" className="error-home-link">Return to Home</Link>
      </div>
    );
  }

  return (
    <div className="success-page">
      <div className="success-container">
        <FaCheckCircle className="success-icon" />
        <h1>Subscription Successful!</h1>
        <p className="success-message">
          Your subscription is now active. Thank you for choosing <strong>ByteHold</strong>!
        </p>
        <div className="activation-key-box">
          <p>Your Activation Key:</p>
          <strong>{activationKey}</strong>
        </div>
        <p className="discord-message">
          Use /subscription activate in the ByteHold Discord to activate your subscription
        </p>
        <Link to="/" className="success-home-link">Return to Home</Link>
      </div>
    </div>
  );
}

export default Success;