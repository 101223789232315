// src/components/Loading.js
import React from 'react';
import './Loading.css';
import logo from '../assets/CogBlue.png'; // Adjust path if needed

const Loading = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-content">
        <img src={logo} alt="Loading..." className="loading-cog-static" /> {/* No spinning animation */}
        <p className="loading-text">Your payment is processing...</p>
      </div>
    </div>
  );
};

export default Loading;