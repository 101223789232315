// client/src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar'; // Import Navbar
import Home from './components/Home';
import Subscriptions from './components/Subscriptions';
import Checkout from './components/Checkout';
import Success from './components/Success';
import Help from './components/Help';

function App() {
  return (
    <Router>
      <Navbar /> {/* Navbar is now visible on all pages */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/success" element={<Success />} />
        <Route path="/help" element={<Help />} />
      </Routes>
    </Router>
  );
}

export default App;