import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import "./Checkout.css";
import Loading from "./Loading";

const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/configuration`);
const publishableKey = response.data?.publishableKey;
if (!publishableKey) {
  throw new Error("Stripe publishable key is not available");
}

const stripePromise = loadStripe(publishableKey);

function Checkout() {
  const [elements, setElements] = useState(null); // Store Stripe Elements instance
  const [email, setEmail] = useState(""); // Store email input
  const location = useLocation();
  const navigate = useNavigate();
  const { price, bytes } = location.state || {};
  const [clientSecret, setClientSecret] = useState("");
  const [checkoutMessage, setCheckoutMessage] = useState("");
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/appearance`);
        setClientSecret(response.data?.clientSecret);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [bytes]);

  useEffect(() => {
    const setupPaymentElement = async () => {
      if (!clientSecret) return;

      const stripe = await stripePromise;
      const appearance = {
        theme: "stripe",
        variables: {
          fontFamily: "Roboto, sans-serif",
          colorPrimary: "#007BFF",
        },
      };
      const stripeElements = stripe.elements({ clientSecret, appearance });

      setElements(stripeElements); // Save the elements instance

      const paymentElement = stripeElements.create("payment", {
        layout: "tabs",
      });

      paymentElement.mount("#payment-element");

      const linkAuthenticationElement = stripeElements.create("linkAuthentication");
      linkAuthenticationElement.mount("#link-authentication-element");

      linkAuthenticationElement.on("change", (event) => {
        setEmail(event.value.email || ""); // Update email from event
      });
    };

    setupPaymentElement();
  }, [clientSecret]);

  const handlePayment = async (event) => {
    event.preventDefault();
    setIsProcessingPayment(true);

    if (!elements || !clientSecret) {
      setCheckoutMessage("<h1>Error</h1><p>Payment setup is incomplete. Please refresh the page and try again.</p>");
      setIsProcessingPayment(false);
      return;
    }

    try {
      const stripe = await stripePromise;

      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email,
            },
          },
        },
        redirect: 'if_required',
      });      

      if (error) {
        throw new Error(error.message);
      }

      // Finalize subscription setup on backend
      const checkoutResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/checkout`, {
        email,
        plan: bytes,
        paymentMethodId: setupIntent.payment_method,
      });

      if (checkoutResponse.data.success) {
        setCheckoutMessage(
          `<h1>Success!</h1><p>Your subscription was created successfully. Activation Key: ${checkoutResponse.data.activationKey}</p>`
        );
        navigate("/success", { state: { activationKey: checkoutResponse.data.activationKey } });
      } else {
        throw new Error("Subscription creation failed on the server.");
      }
    } catch (error) {
      console.error(error.toString());
      setCheckoutMessage(`<h1>Error</h1><p>${error.message}</p>`);
    } finally {
      setIsProcessingPayment(false);
    }
  };

  return (
    <div className="checkout-container">
      {isProcessingPayment && <Loading overlay />} {/* Loading animation */}

      <h1 className="checkout-title">Subscribe to ByteHold</h1>
      <p className="checkout-description">
        You have selected the <strong>{bytes} Byte{bytes > 1 ? "s" : ""}</strong> plan at{" "}
        <strong>${price.toFixed(2)}</strong> per month.
      </p>
      <div className="checkout-message" dangerouslySetInnerHTML={{ __html: checkoutMessage }}></div>

      <form id="payment-form" onSubmit={handlePayment}>
        {/* Payment Element */}
        <div id="payment-element"></div>
        <div id="link-authentication-element"></div>

        <br />

        <button
          id="submit-button"
          className="submit-button"
          disabled={isProcessingPayment || !clientSecret}
        >
          {isProcessingPayment ? "Processing Payment..." : "Complete Payment"}
        </button>
      </form>
    </div>
  );
}

export default Checkout;